<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ division.name }} - {{ $t('notices') }}</h5>
      <div class="btns">
        <b-form-input type="search" v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" :to="{ name: 'DivisionBackNoticesCreate', params: { lang: lang, console: console, tournamentId: tournamentId, divisionId: divisionId } }">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="notices" :fields="columns" :filter="filter">
      <template slot="image" slot-scope="row">
        {{ row.value ? $t('yes') : $t('not') }}
      </template>
      <template slot="show" slot-scope="row">
        {{ row.value ? $t('yes') : $t('not') }}
      </template>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button variant="success" class="mx-2" :to="{ name: 'DivisionBackNoticesEdit', params: { lang: lang, console: console, tournamentId: tournamentId, divisionId: divisionId, noticeId: row.item.id } }">
            <i class="fa fa-pencil"></i>
          </b-button>
          <b-button variant="danger" class="mx-2" @click="openModalDestroy(row.item)">
            <i class="fa fa-trash"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ notice.title}}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('not')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('yes')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      filter: '',
      columns: [
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'image', label: this.$t('image'), sortable: true },
        { key: 'show', label: this.$t('show'), sortable: true },
        { key: 'views', label: this.$t('comments'), sortable: true },
        { key: 'reviews', label: this.$t('views'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true },
        { key: 'actions', label: '' }
      ],
      notices: [],
      notice: {},
      tournamentId: this.$route.params.tournamentId,
      divisionId: this.$route.params.divisionId
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'division',
      'user',
      'console'
    ])
  },
  mounted() {
    if (!this.division.name) {
      this.$router.push({ name: 'DivisionBack', params: { lang: this.lang, console: this.console, tournamentId: this.tournamentId, divisionId: this.divisionId } })
      return
    }
    this.changeTitleBar(this.division.name + ' - ' + this.$t('notices'))
    this.fetchData()
  },
  methods: {
    openModalDestroy (notice) {
      this.notice = notice
      this.$refs.modalDelete.show()
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${this.divisionId}/notices`
      this.$axios.get(path).then((response) => {
        this.notices = response.data.data
      })
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${this.divisionId}/notices/${this.notice.id}/delete`
      this.$axios.delete(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.notices = this.notices.filter(notice => {
          return this.notice.id !== notice.id
        })
        this.$refs.modalDelete.hide()
      })
    }
  }
}
</script>
